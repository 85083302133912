import {mapActions, mapGetters, mapMutations} from "vuex";


export default {
  name: "order-item",
  components:{
  },
  props: {
    item: {
      type: Object,
      default: ()=> {}
    }
  },
  data(){
    return{
    }
  },
  created() {

  },
  computed:{
    ...mapGetters({

    })
  },

  methods:{
    ...mapActions({

    }),
    ...mapMutations({
    }),

  }
}